import React, { useState, useContext, useEffect } from "react";

import styled from "styled-components";
import Header from "../../components/Header";
import axios from "axios";
import LoadingSpinner from "../../components/LoadingSpinner";
import { API_ROOT } from "../../constants";
import { UserContext } from "../../components/Layout";
import Pagination from "../../components/Pagination";
import { Link } from "gatsby";

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  min-height: 130vh;

  button#add-channel {
    margin-right: 15px;
  }

  button {
    display: flex;
    align-items: center;
    width: max-content;
    margin-bottom: 15px;
  }

  .video-list-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(2, 47%);
      gap: 20px;
    }
    a {
      border: 1px solid transparent;
      text-decoration: none;
      .video-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;

        p {
          color: var(--color);
          height: 70px;
          margin-bottom: 10px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          padding: 0;
          margin: 0;
          font-weight: bold;
          font-size: 18px;
          text-align: center;
          &:hover {
            text-decoration: underline;
          }
        }

        figure {
          padding: 0;
          margin: 0;
          margin-bottom: 5px;
          height: 0;
          padding-top: calc(9 / 16 * 100%);
          position: relative;
          width: 100%;

          &:hover + p {
            text-decoration: underline;
          }

          img {
            height: 100%;
            width: fit-content;
            margin: 0 auto;
            object-fit: contain;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 3px;
            cursor: pointer;
            overflow: hidden;
            border: 1px solid var(--border-color);
            animation: fadeIn 0.5s ease;
          }
          /* Lang */
          span {
            position: absolute;
            top: -3px;
            right: 0px;
            background: #000;
            border: 1px solid #222;
            border-top: 4px solid #222;
            padding: 5px 8px;
            text-transform: uppercase;
            color: #fff;
            border-radius: 2px;
          }
        }
      }
    }
  }
`;

export const langMap = {
  fr: "Français",
  es: "Español",
  en: "English",
  de: "Deutsch",
  pt: "Português",
  nl: "Nederlands",
  it: "Italiano",
};

function renderLang(lang) {
  return langMap[lang] ?? "";
}

const channelThumbnailMap = {
  "https://yt3.ggpht.com/xG8T6E5IDBuocTLAHEDci4V5FOfR2tYGZ75MFhtTmcb28lMtrFpZP6wsqolQDqaj8MdUnjFWjA=s800-c-k-c0x00ffffff-no-rj":
    "https://yt3.ggpht.com/FNFiBa3J8kjILSjrPLEnB_ULXjp8-iLRVCl148Vf_zjeJySDPWmygi4g9UKHUtcq6geNf9yL=s800-c-k-c0x00ffffff-no-rj",
  "https://yt3.ggpht.com/ZP49-Uq_TKKQNdi4sFU6cQG-i0bdkl8ucYV1wdbehdgkpJBli1zsDI6Ekp9MlfNiZCY04ZL5=s800-c-k-c0x00ffffff-no-rj":
    "https://yt3.googleusercontent.com/zKfPb7Is4-_X0u-UzZCb6rizCtTM7gnSe2tQAnBm-WDmFCumAiV1RQsZcqgqkBvpFwCfyiUJCmU=s160-c-k-c0x00ffffff-no-rj",
};

function CatalogChannelList(props) {
  // ! Remove page eventually
  const { lang, page = 1 } = props;
  const [channels, setChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useContext(UserContext);

  useEffect(() => {
    if (user.uuid) {
      try {
        axios
          .get(
            API_ROOT + "catalog-videos/channels/" + user.uuid + "?lang=" + lang
          )
          .then(({ data }) => {
            // if (data.channels.length < 1) {
            //   navigate("/app/videos/add-video");
            // }
            setIsLoading(false);
            setChannels(data.channels);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    }
  }, [user, page]);

  // if (isLoading && !videos.length) {
  //   // Add loading spinner component
  //   return <LoadingSpinner />;
  // }
  return (
    <Container>
      <Header>{renderLang(lang)} Channels</Header>
      {/* <Pagination
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        userUuid={user.uuid}
        curPage={page}
        hasMore={channels.length === 7}
        path={`catalog/${lang}/`}
      /> */}
      <div className="video-list-container">
        {channels.map((chan, idx) => {
          // * Handling change in channel thumbnail for Ingrid French
          // * Will do an SQL statement to set the thumbnail, eventually
          const channelThumbnail =
            channelThumbnailMap[chan.channelThumbnail] ?? chan.channelThumbnail;

          return (
            <Link
              key={chan.channelId}
              alt={chan.channelTitle}
              to={`/app/catalog/${chan.channelId}/1`}
            >
              <div className="video-card">
                <figure>
                  <img src={channelThumbnail} alt={chan.channelTitle} />
                  {/* <span>{lang}</span> */}
                </figure>
                <p className="title">{chan.channelTitle}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </Container>
  );
}

export default CatalogChannelList;
