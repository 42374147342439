import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import { Link } from "gatsby";
import LoadingSpinner from "../../components/LoadingSpinner";
import { UserContext } from "../../components/Layout";
import { API_ROOT } from "../../constants";
import axios from "axios";

const Container = styled.div`
  .language-list-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    max-width: 600px;
    margin: 0 auto;

    a {
      text-decoration: none;
      color: var(--color);
      article {
        border: 1px solid #4e4d4d;
        padding: 16px 12px 8px 12px;
        padding-top: 12px;
        border-radius: 3px;
        background: var(--black-to-bg);

        h2 {
          font-size: 34px;
          text-align: center;
          padding-bottom: 12px;
          margin: 0;
        }
        .info-container {
          display: flex;
          justify-content: space-between;

          p {
            padding: 0;
            margin: 0;
            text-align: end;
            font-size: 16px;
          }

          p + p {
            padding-bottom: 8px;
          }

          @media screen and (max-width: 600px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }

      &:hover button {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .language-list-container {
      display: flex;
      flex-direction: column;
    }
  }
`;

const StyledLink = styled(Link)`
  opacity: 0;
  animation: fadeIn ${(props) => props.duration * 140}ms ease;
  animation-fill-mode: forwards;
`;

function Catalog() {
  const [data, setData] = useState(null);
  const user = useContext(UserContext);

  useEffect(() => {
    if (user.uuid) {
      axios
        .get(`${API_ROOT}catalog-videos/info/${user.uuid}`)
        .then(({ data }) => {
          setData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  if (!data) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Header>Language Catalog</Header>
      <div className="language-list-container">
        <StyledLink duration={1} to="/app/catalog/en">
          <article>
            <div>
              <h2>English</h2>
              <div className="info-container">
                <p>{data.enChannelCount} Channels</p>
                <p>{data.enVideoCount} Videos </p>
              </div>
            </div>
            <button>View</button>
          </article>
        </StyledLink>
        <StyledLink duration={2} to="/app/catalog/es">
          <article>
            <div>
              <h2>Español</h2>
              <div className="info-container">
                <p>{data.esChannelCount} Channels</p>
                <p>{data.esVideoCount} Videos </p>
              </div>
            </div>
            <button>View</button>
          </article>
        </StyledLink>
        <StyledLink duration={3} to="/app/catalog/fr">
          <article>
            <div>
              <h2>Français</h2>
              <div className="info-container">
                <p>{data.frChannelCount} Channels</p>
                <p>{data.frVideoCount} Videos </p>
              </div>
            </div>
            <button>View</button>
          </article>
        </StyledLink>
        <StyledLink duration={4} to="/app/catalog/de">
          <article>
            <div>
              <h2>Deutsch</h2>
              <div className="info-container">
                <p>{data.deChannelCount} Channel</p>
                <p>{data.deVideoCount} Videos </p>
              </div>
            </div>
            <button>View</button>
          </article>
        </StyledLink>
        <StyledLink duration={5} to="/app/catalog/it">
          <article>
            <div>
              <h2>Italiano</h2>
              <div className="info-container">
                <p>{data.itChannelCount} Channel</p>
                <p>{data.itVideoCount} Videos </p>
              </div>
            </div>
            <button>View</button>
          </article>
        </StyledLink>
        <StyledLink duration={6} to="/app/catalog/pt">
          <article>
            <div>
              <h2>Português</h2>
              <div className="info-container">
                <p>{data.ptChannelCount} Channel</p>
                <p>{data.ptVideoCount} Videos </p>
              </div>
            </div>
            <button>View</button>
          </article>
        </StyledLink>
        <StyledLink duration={7} to="/app/catalog/nl">
          <article>
            <div>
              <h2>Nederlands</h2>
              <div className="info-container">
                <p>{data.nlChannelCount} Channel</p>
                <p>{data.nlVideoCount} Videos</p>
              </div>
            </div>
            <button>View</button>
          </article>
        </StyledLink>
      </div>
      {/* <Link to="/app/catalog/de">
        <article>
          <div>
            <h2>Deutsch</h2>
            <p>Number of  videos</p>
          </div>
          <button>View</button>
        </article>
      </Link> */}
    </Container>
  );
}

export default Catalog;
